.nav_container {
  position: absolute;
  right: 10.8vw;

  &__btn {
    color: var(--jacksons-purple);
    background-color: transparent;
    border: transparent;
    cursor: pointer;
    font: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
}
