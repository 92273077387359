html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-initial);
  color: var(--jacksons-purple);
}

.title {
  font-size: 36px;
  letter-spacing: 0.36px;
  text-align: center;
}
