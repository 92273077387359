.container__return {
    color: var(--jacksons-purple);
    justify-content: flex-start;
    letter-spacing: 0.18px;
    text-decoration: none;
    font-stretch: normal;
    align-items: center;
    font-style: normal;
    position: absolute;
    font-weight: bold;
    font-size: 18px;
    line-height: 1;
    display: flex;
    & > * {
        padding: 5px;
    }
    &:hover {
        color: var(--red-violet);
    }
}
